import * as React from 'react'
import PropTypes from "prop-types"
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination} from 'swiper';

// Components
import Layout from '../../components/layout/Layout'

SwiperCore.use([Pagination]);

// Partenaire
const Partenaire = ({ pageContext, data }) => {
  return (
    <Layout pageTitle={data.partenaire.frontmatter.nom} pageDescription={data.partenaire.frontmatter.body} headerClassPosition="sticky-top">
      <section key="breadcrumbs-section" className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            {
              data.partenaire.frontmatter.nom
              ? <h2>{data.partenaire.frontmatter.nom}</h2>
              : null
            }
            <ol>
              <li>
                <Link to={`/partenaires`} className="text-reset">Partenaires</Link>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section key="details-section" className="partner-details">
        <div className="container">
          <div className="row gy-4">
            {data.partenaire.frontmatter.images ? 
              <div key="images" className="col-lg-8">
                  <Swiper
                    modules={[Navigation, Pagination]}
                    navigation
                    pagination={{"clickable": true}}
                    className="partner-details-slider swiper"
                  >
                    {
                      data.partenaire.frontmatter.images.map((image, index) => (
                        <SwiperSlide key={index} className="swiper-slide">
                          <GatsbyImage
                            image={getImage(image)}
                            className="swiper-slide"
                            alt={data.partenaire.frontmatter.nom}
                          />
                        </SwiperSlide>                  
                      ))
                    }
                  </Swiper>
              </div>
            : null}
            <div key="text" className="col-lg-4">
              <div key="text" className="partner-info">
                <div className="mb-4 border-bottom">
                  <GatsbyImage
                    image={getImage(data.partenaire.frontmatter.hero_image_16x9)}
                    className="card-img-top"
                    alt={data.partenaire.frontmatter.hero_image_alt}
                  />
                </div>
                <ul>
                  {
                    data.partenaire.frontmatter.pays
                    ? <li key="pays"><strong className="me-1">Pays:</strong>{data.partenaire.frontmatter.pays}</li>
                    : null
                  }
                  {
                    data.partenaire.frontmatter.site_internet
                    ? <li key="site_internet" className="text-center"><a href={data.partenaire.frontmatter.site_internet} className="btn btn-sm btn-primary text-white" target="_blank" rel="noreferrer">Site internet</a></li>
                    : null
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section key="description-section" className="partner-details">
          <div className="container">
            <div key="description" className="partner-description">
                <h2>Informations générales</h2>
                <div className="text-justify">
                  <MDXRenderer>
                    {data.partenaire.body}
                  </MDXRenderer>
                </div>
              </div>
          </div>
      </section>
      {data.partenaire.frontmatter.videos ? 
        <section key="videos-section" className="videos">
          <div className="container">
            <div key="videos-title" className="section-title">
              <h2>Vidéos</h2>
            </div>
            <div key="videos" className="row">
                {
                  data.partenaire.frontmatter.videos.map((video, index) => (
                    <div key={index} className="col-lg-4">
                      <div className="ratio ratio-16x9 mb-3">
                        <iframe src={video} title={data.partenaire.frontmatter.nom} allowFullScreen></iframe>
                      </div>
                    </div>
                  ))
                }
            </div>
          </div>
        </section>
      : null }
    </Layout>
  )
}

Partenaire.propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    partenaire: PropTypes.shape({
      node: PropTypes.shape({
        frontmatter: PropTypes.shape({
          title: PropTypes.string.isRequired,
        }),
        fields: PropTypes.shape({
          slug: PropTypes.string.isRequired,
        }),
      }),
    }).isRequired
  }),
}

export default Partenaire

export const query = graphql`
  query ($id: String) {
    partenaire: mdx(id: {eq: $id}, fileAbsolutePath: {regex: "/partenaires/"}) {
      frontmatter {
        nom
        pays
        site_internet
        hero_image {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
        hero_image_16x9 {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
        hero_image_alt
        hero_image_credit_link
        hero_image_credit_text
        images {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
        videos
        actif
        date(formatString: "MMMM D, YYYY")
      }
      body
    }
  }
`